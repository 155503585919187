@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@font-face {
    font-family: 'AldotheApache';
    src: url('./fonts/AldotheApache.eot');
    src: url('./fonts/AldotheApache.eot?#iefix') format('embedded-opentype'),
         url('./fonts/AldotheApache.ttf') format('truetype'),
         url('./fonts/AldotheApache.woff') format('woff'),
         url('./fonts/AldotheApache.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #3c3c3c #1E1E1E;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #1E1E1E;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 5px;
  }

*
{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;

}

body{
    background: none;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: #3c3c3c;
    scroll-behavior: smooth;
}

#connect-wallet{
    border: none;
    width: 10vw;
    height: 2vw;
    border-radius: 10vw;
    margin-right: 5vw;
    background-color: #FF3F29;
    color: white;
    font-family: 'AldotheApache', sans-serif;
    font-size: 1vw;
    cursor: pointer;
}


#logo{
    width: 5vw;
    margin-left: 5vw;
    display: flex;
    margin-top: 1vw;
}

#header
{   
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: fixed;
    background-color: #1e1e1e81;
    justify-content: space-between;
    top: 0;
    height: 4vw;
}


#header ul{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

#header ul li {
    list-style: none;
    margin-left: 3vw;
    font-size: 1vw;

}

#header ul li a{
    text-decoration: none;
    padding: 0.5vw 2.5vw;
    color:white;
    background-color: #00000060;
    border-radius: 5vw;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 1px;
    font-size: 1vw;

}

#header ul li a:hover {
    color:#FF3F29;
}

#mobile-header
{   
    align-items: center;
    width: 100%;
    display: none;
    flex-direction: row;
    z-index: 99;
    position: fixed;
    background-color: #1e1e1e81;
    justify-content: space-between;
    top: 0;
    height: 8vh;
}


#mobile-header ul{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

#mobile-header ul li {
    list-style: none;
    margin-left: 3vw;
    font-size: 3vh;

}

#mobile-header ul li a{
    text-decoration: none;
    padding: 1vh 2vh;
    color:white;
    background-color: #00000060;
    border-radius: 5vw;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 1px;
    font-size: 2.2vh;

}

#mobile-header ul li a:hover {
    color:#FF3F29;
}

#mobile-connect-wallet{
    margin-left: 4vw;
    border: none;
    width: 40vh;
    height: 4.1vh;
    border-radius: 10vw;
    margin-right: 5vw;
    background-color: #FF3F29;
    color: white;
    font-family: 'AldotheApache', sans-serif;
    font-size: 2.2vh;
    cursor: pointer;
}

section{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-behavior: smooth;

}

section img {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    transition-timing-function: ease;
}

.menu-title{
    margin-top: 2vw;
    font-size:5vw;
    color:white;
    margin-bottom: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    font-family: 'AldotheApache', sans-serif;
    text-shadow: 1px 5px #ee4e27;
    letter-spacing: 5px;
}

.mobile-title{
    margin-top: 5vh;
    font-size:15vw;
    color:white;
    margin-bottom: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    font-family: 'AldotheApache', sans-serif;
    text-shadow: 1px 5px #ee4e27;
    letter-spacing: 5px;
}

.roadmap-container{
    position: absolute;
    width: 100%;
    height: 56vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.teams-container{
    position: absolute;
    width: 100%;
    height: 56vw;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.team-names{
    display: flex;
    flex-direction: row;
    height: 5vw;
    align-items: center;
    justify-content: center;
    margin-top: 7.5vw;
}

.team-name{
    height: 4vw;
    width: 11vw;
    margin-left: 2vw;
    margin-right: 2.4vw;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.team-name a{
    color: white;
    font-family: 'AldotheApache', sans-serif;
    font-size: 2vw;
}
.team-name p{
    color: white;
    font-size:0.7vw;
    margin-top:-0.5vw;
}

.story-container{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    margin-top: 10vw;
}

.story-container iframe{
    border: 10px solid #1E1E1E;
}

.mobile-mint-container{
    min-height: 100vh;
    width: 100%;
    height: 100%;
    margin-top: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(./images/mint.png);
    background-repeat: no-repeat;
}

.mobile-mint-container button{
    width: 50%;
    height: 10vh;
    font-family: 'AldotheApache', sans-serif;
    font-size: 8vw;
    border: 0.25vw solid #1E1E1E;
    border-radius: 2vh;
    background-color: #FF3F29;
    color: white;
    -webkit-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    animation: zoom-in-zoom-out 2s ease infinite;
    cursor: pointer;
}

.mint-container{
    position: absolute;
    width: 100%;
    height: 56vw;
    margin-top: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mint-container a{
    text-decoration: none;
    color: white;
    font-family: 'AldotheApache', sans-serif;
}

.mint-container button{
    width: 15vw;
    height: 5vw;
    font-family: 'AldotheApache', sans-serif;
    font-size: 2vw;
    border: 0.25vw solid #1E1E1E;
    border-radius: 1vw;
    background-color: #FF3F29;
    color: white;
    -webkit-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    animation: zoom-in-zoom-out 2s ease infinite;
    cursor: pointer;
}

@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.3, 1.3);
    }
    100% {
      transform: scale(1, 1);
    }
  }

.story-container p{
    width: 60%;
    text-align: center;
    color: white;
}

.roadmap-first-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5vw;
}

.roadmap-second-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5vw;
}


.fix-images{
    width: 4vw;
    height: 4vw;
}

#current-step{
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.roadmap-step h2{
    padding-top: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.roadmap-step li{
    padding-top: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.roadmap-step h2 {
    font-size: 1.5vw;
}

.roadmap-step li {
    font-size: 0.8vw;
    padding: 0;
}

.roadmap-step{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    margin-left: 5vw;
    margin-right: 5vw;
    min-width: 10vw;
    max-width: 20vw;
    background-color: #1e1e1e73;
    padding: 2vw;
    border-radius:1vw;
}

.roadmap-step b{
    color:#FF3F29;
}

.mobile-roadmap-step h2{
    font-family: 'AldotheApache', sans-serif;
    text-shadow: 1px 2px #ee4e27;
    letter-spacing: 3px;
    color: white;
}

.mobile-roadmap-step li{
    font-family: 'Poppins', sans-serif;
    color: white;
    list-style:square;
    margin-top: 1vh;
}

.mobile-roadmap-step h2 {
    font-size: 6vh;
}

.mobile-roadmap-step li {
    font-size: 3vh;
    padding: 0;
}

.mobile-roadmap-step:first-child{
    margin-top: 40vh;

}

.mobile-roadmap-step{
    padding: 10vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    background-color: #1e1e1e73;
    border-radius:1vw;
}

.mobile-roadmap-step b{
    color:#FF3F29;
}

.mobile-roadmap-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(./images/roadmap-mobile.png);
    padding-bottom: 10vh;
}

.story-container-mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(./images/story-mobile.png);
    padding-bottom: 10vh;
    min-height: 100vh;
    color: white;
    text-align: center;
    font-size: 4vw;
}

.story-container-mobile b{
    color: #FF3F29;
}

.story-container-mobile a button{
    margin-top: 10vh;
    height: 10vh;
    font-family: 'AldotheApache', sans-serif;
    font-size: 8vw;
    border: 0.25vw solid #1E1E1E;
    border-radius: 2vh;
    background-color: #FF3F29;
    color: white;
    -webkit-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: -5px 5px 0px 0px rgba(0,0,0,0.75);
    animation: zoom-in-zoom-out 2s ease infinite;
    cursor: pointer;
    width: 55vw;
}




.in-site{
    margin-top: -7px;
    border-top: 1vh solid #1E1E1E;
}

.mobile-container{
    display: none;
    margin-top: -7px;
    border-top: 1vh solid #1E1E1E;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    transition-timing-function: ease;}

.mobile-container img{
    width: 100%;
}

.faq-container{
    position: absolute;
    width: 100%;
    height: 56vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.first-column{
    display: flex;
    flex-direction: column;
}

.faq-container-mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(./images/faq-mobile.png);
    background-repeat: no-repeat;
    padding-bottom: 10vh;
    color: white;
}

.mobile-question-container:first-child{
    margin-top: 30vw;
}

.mobile-question-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    background-color: #37373796;
    border: 0.25vw solid rgba(0, 0, 0, 0.315);
    border-radius: 0.5vw;
}

.mobile-question{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:1vw;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.mobile-question h2{
    font-size: 4vh;
    padding: 1vh;
}

.mobile-answer{
    background-color: #1e1e1e59;
    width: 100%;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}

.mobile-answer p {
    font-size: 3vh;
    padding: 2vh;
    color: white;
}


.answer{
    background-color: #1e1e1e59;
    width: 100%;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}

.answer p {
    font-size: 0.8vw;
    color: white;
}

#answer-deactive{display: none;}
#answer-active{
    text-align: center;
    font-size: 0.9vw;
    padding: 10px;}

.question-container{
    display: flex;
    flex-direction: column;
    width: 25vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 2vw;
    background-color: #37373796;
    border: 0.25vw solid rgba(0, 0, 0, 0.315);
    border-radius: 0.5vw;
}

.question{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:1vw;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.question h2{
    font-size: 1.3vw;
}

.question button{
    background: #FF3F29;
    border: none;
    font-size: 2.1vw;
    width: 3vw;
    height: 3vw;
    border-radius: 1vw;
    cursor: pointer;
}

.in-site img{
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    transition-timing-function: ease;
}

section #rightdoor, section #leftdoor {
z-index: 2;
}
section #entry {
z-index: 5;
}

section #afelogo{
    position: absolute;
    top: 13vw;
    z-index: 1;
}

section #menu_screen{
    position:relative
}

section #team_screen{
    position:relative

}

section #text
{
    position: absolute;
    color: rebeccapurple;
    font-size: 10vw;
    text-align: center;
    line-height: 0.55em;
    transform: translateY(-50px);
}

section #text span
{
    font-size: 0.20em;
    letter-spacing: 2px;
    font-weight: 400;
}

#btn{
    text-decoration: none;
    display: inline-block;
    padding: 8px 30px;
    background-color: #ee4e27;
    color: white;
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 2px;
    border-radius: 40px;
    transform: translateY(150px);
    z-index: 1;
}

.mobile-footer{
    background-color: #FF3F29;
    position: absolute;
    width: 100%;
    bottom: -1;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -7px;
    min-height: 8vh;
}

.mobile-footer h2 {
    color:white;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 2px;
    font-size: 4vh;
    padding: 0;
}
.mobile-footer h5 {
    color:white;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 2px;
    font-size: 1.5vh;
    text-align: center;
}

.mobile-socials{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-socials img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1vh;
    width: 6vh;
}


.footer
{
    padding-left: 50px;
    background-color: #FF3F29;
    position: absolute;
    width: 100%;
    bottom: -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -7px;
    min-height: 3vw;
}

.footer h2 {
    color:white;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 2px;
    font-size: 1.2vw;

}

.footer h5 {
    color:white;
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 2px;
    font-size: 0.7vw;
}

.footer p{
    font-size: 1em;
    color:white;
}
.socials{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1vw;
    width: 3vw;
}

.nfts-container{
    width: 100%;
    border-top: 1vh solid #1E1E1E;
    display: flex;
    flex-direction: row;
    height: 20vw;
    align-items: center;
    justify-content:flex-start;
    background-color: #343434;
}

.mobile-showcase{
    display: none;
}

.mobile-showcase img {
    height: 40vh;
    padding-left: 2vw;
    padding-right: 2vw;
    animation: slide-images 20s linear infinite;
}

.discord-container{
    width: 100%;
    border-top: 1vh solid #1E1E1E;
    display: flex;
    flex-direction: row;
    height: 20vw;
    align-items: center;
    justify-content:space-between;
    background-color: #343434;
    margin-top: -0.5vw;
}

.discord-left img{
    width: 15vw;
}

.discord-right{
    color: white;
    font-size: 1vw;
    margin-left: -10vw;
}

.discord-right h1{
    font-family: 'AldotheApache', sans-serif;
    letter-spacing: 0.1vw;
}

.discord-right p{
    color: white;
    font-size: 1vw;
}

.discord-right button{
    font-family: 'AldotheApache', sans-serif;
    font-size: 1.2vw;
    border: none;
    padding: 0.5vw;
    min-width: 10vw;
    margin-top: 1vw;
    background-color: #7289DA;
    color: white;
    border-radius: 2vw;
    cursor: pointer;
}

.nfts-container img {
    height: 15vw;
    padding-left: 2vw;
    padding-right: 2vw;
    animation: slide-images 20s linear infinite;
}


@keyframes slide-images{
    0% {
      transform:translateX(0px);
    }
    50% {
      transform:translateX(-90vw);
    }
    100% {
        transform:translateX(0px);
      }
  }

@media only screen and (max-width: 768px) {
    section #rightdoor, section #leftdoor {
        display: none;
        }
        section #entry {
        display: none;
        }

        .in-site{
            display: none;
        }

        section {
            display: none;
        }

        .discord-container{display: none;}
        .nfts-container{display: none;}

        .mobile-container{
            display: block;
            margin-top: -7px;
            border-top: 1vh solid #1E1E1E;
            width: 100%;
            object-fit: cover;
            pointer-events: none;
            transition-timing-function: ease;}

        .footer{display: none;}

        #header{display: none;}

        #mobile-header
        {   
            align-items: center;
            width: 100%;
            display: flex;
            flex-direction: row;
            z-index: 99;
            position: fixed;
            background-color: #1e1e1e81;
            justify-content: space-between;
            top: 0;
            height: 8vh;
        }
        .mobile-footer{
            padding-left: 5vh;
            background-color: #FF3F29;
            position: absolute;
            width: 100%;
            bottom: -1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: -7px;
            min-height: 8vh;
        }
        .mobile-showcase{
            width: 100%;
            border-top: 1vh solid #1E1E1E;
            display: flex;
            flex-direction: row;
            height: 50vh;
            margin-bottom: 1vh;
            align-items: center;
            justify-content:flex-start;
            background-color: #343434;
            margin-top: -1.3vh;
        }
  }